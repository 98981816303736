@font-face {
  font-family: "manrope";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(Manrope-Light.ttf);
}
@font-face {
  font-family: "manrope";
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url(Manrope-ExtraLight.ttf);
}
@font-face {
  font-family: "manrope";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(Manrope-Regular.ttf);
}
@font-face {
  font-family: "manrope";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(Manrope-Medium.ttf);
}
@font-face {
  font-family: "manrope";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(Manrope-SemiBold.ttf);
}
@font-face {
  font-family: "manrope";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(Manrope-Bold.ttf);
}
@font-face {
  font-family: "manrope";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(Manrope-ExtraBold.ttf);
}
