html {
  width: 100vw;
  height: 100vh;
  margin: auto;
  background: linear-gradient(196deg, rgb(49, 56, 60) 3%, rgb(35, 36, 40) 55%);
  padding: 0;
}
.app-light {
  color: black;
  background-color: whitesmoke;
}
.app-dark {
  color: rgb(221, 226, 237, 1);
}
.app {
  margin: 0;
  overflow-y: scroll;
  position: relative;
  display: flex;
  height: 100vh;
  align-items: center;
}
