.banner__wrapper {
  margin: 100px auto;
  font-size: 14px;
  margin-left: 4vw;
}

.title-hidden {
  transform: translateX(5vw);
  transition: all 0.6s 0s ease-out;
  opacity: 0;
}
.title-visible {
  transform: translateX(0vw);
  opacity: 1;
  transition: all 1s 0s ease-out;
}

.banner__title {
  margin-top: 0;
  font-size: 5vmax;
  font-family: urbanist;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 3vmin;
  transition: all 0.3s linear;
  text-shadow: 0.4vmin 0.4vmin 0.6vmax rgb(0, 0, 0, 0.6);
}
.banner__title:hover {
  font-weight: 200;
  transition: all 0.3s linear;
}
/* .banner__subtitle_item:first-child:hover {
  font-weight: 700;
  transition: all 0.5s linear;
}
.banner__subtitle_item:nth-child(2):hover {
  color: red;
  font-weight: 800;
}
.banner__subtitle_item:nth-child(3):hover {
  font-weight: 100;
  transition: all 0.5s linear;
} */

.banner__subtitle {
  font-family: "urbanist";
  font-size: 1vmax;
  font-weight: 700;
  margin-left: 3vmin;
}

.banner__subtitle_item {
  font-weight: 300;
  font-size: 2.5vmax;
  margin: 2vmin;
  text-shadow: 0.25vmin 0.25vmin 0.35vmax rgb(0, 0, 0, 1);
}

.banner__subtitle_item:first-child {
  position: relative;
}
.banner__subtitle_item:nth-child(3) {
  font-weight: 600;
  margin-top: 1vmin;
  font-size: 2.6vmax;
  position: relative;
  text-shadow: 0.3vmin 0.3vmin 0.5vmax black;
}
.name {
  background: linear-gradient(
    43deg,
    rgba(102, 208, 210, 0.852000175070028) 3%,
    rgba(98, 187, 213, 1) 100%
  );
  position: relative;
  animation: rainbow 2s ease-in-out infinite;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 200;
  z-index: 1;
  margin: 0;
  margin-left: 1em;
  margin-top: 0.3em;
  transition: color 0.2s 0s ease-in-out;
  filter: drop-shadow(0.4vmin 0.3vmin 0.3vmax black);
}
.name-hidden {
  opacity: 0;
  transform: translateX(-6vw);
  transform: translateY(7vh);
  font-size: 6.6vmax;
  transition: all 1.6s linear;
}
.name-visible {
  opacity: 1;
  transform: translateX(0vw);
  transition: all 1s 1.2s linear;
  font-size: 5vmax;
}

.name .banner__subtitle_item,
.banner__title,
.name {
  transition: all 0.6s 0s linear;
}

@media screen and (max-width: 720px) {
  .banner__wrapper {
    padding: 1em;
    width: 100vw;
    margin: 0;
  }
  .banner__title {
    font-size: 8.3vmin;
  }
  .banner__subtitle {
    margin: 6vmin auto;
  }
  .banner__subtitle_item {
    font-weight: 400;
    font-size: 4.6vw;
  }
  .banner__subtitle_item:nth-child(3) {
    font-size: 5.2vw;
    font-weight: 600;
    margin-top: 0.5em;
  }
  .name {
    font-size: 10.4vmin;
    font-weight: 200;
    margin-top: 5vmin;
    text-align: center;
  }
}

@media screen and (max-width: 600) {
  .banner__subtitle_item {
    margin: 10px;
  }
}
@keyframes rainbow {
  0% {
    background-position: left;
  }
  50% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}
