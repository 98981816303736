@font-face {
  font-family: "baloo 2";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(Baloo2-Bold.ttf);
}
@font-face {
  font-family: "baloo 2";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(Baloo2-ExtraBold.ttf);
}
@font-face {
  font-family: "baloo 2";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(Baloo2-SemiBold.ttf);
}
@font-face {
  font-family: "baloo 2";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(Baloo2-Medium.ttf);
}
@font-face {
  font-family: "baloo 2";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Baloo2-Regular.ttf);
}
